@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@font-face {
  font-family: 'Monoton';
  src: url('https://fonts.googleapis.com/css2?family=Monoton')
}

.title{
  font-family: 'Monoton';
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
      position: absolute;
      left: auto;
      right: 0;
  }
}

.img-responsive.mobile {
  display: none;
}

@media (max-width: 767px) {
  .img-responsive {
    display: none;
  }
  .img-responsive.mobile {
    display: block;
  }
}
