
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

@font-face {
  font-family: 'Monoton';
  src: url('https://fonts.googleapis.com/css2?family=Monoton')
}

.title{
  font-family: 'Monoton';
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
      position: absolute;
      left: auto;
      right: 0;
  }
}

.img-responsive.mobile {
  display: none;
}

@media (max-width: 767px) {
  .img-responsive {
    display: none;
  }
  .img-responsive.mobile {
    display: block;
  }
}